<!--<div *ngIf="isEditing; then postEditingDialog else postDialog"></div>-->
<!--<ng-template #postDialog>-->
<!--<div class="modal-post">-->
<!--  <div class="modal__cont">-->

<!--    <button *ngIf='feedbacksHistory.length' (click)='isEditing = true' class="btn primary-btn-filled modal__button">{{'campaignOverview.post.switchToEditingHistory' | translate}}</button>-->

<!--    <div class="post-info">-->
<!--      <div class="post-info__colum">-->
<!--        <div class="modal-post-info">-->
<!--          <img [src]='profileImage' alt="influencer profile" class="modal-post-info__avatar" (error)="setDefaultProfileImage()">-->
<!--          <div class="modal-post-info__group">-->
<!--            <a *ngIf='post.postSocialType !== "tiktok"' [href]='post.socialAccountInfo.profileUrl' target='_blank' class="modal-post-info__title">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</a>-->
<!--            <p *ngIf='post.postSocialType === "tiktok"' class="modal-post-info__title">{{post.socialAccountInfo.name}}</p>-->
<!--            <span class="modal-post-info__subtitle"><b>{{post.socialAccountInfo.followers}}</b>  {{'campaignOverview.followers' | translate}}</span>-->
<!--          </div>-->

<!--        </div>-->
<!--        <div *ngIf='isShowPrices'>-->
<!--        <div class="post-info-price__wrapper">-->
<!--          <div class="post-info-price">-->
<!--            <div class="post-info-price__colum">-->
<!--              <span class="post-info-price__text">{{'campaignOverview.post.dialog.media' | translate}}</span>-->
<!--              <span class="post-info-price__subtext">{{'campaignOverview.post.dialog.techFee' | translate}}</span>-->
<!--            </div>-->
<!--            <div class="post-info-price__colum">-->
<!--              <span class="post-info-price__text">€ {{post.postPriceTechFee}}</span>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="post-info-price">-->
<!--            <div class="post-info-price__colum">-->
<!--              <span class="post-info-price__text">{{'campaignOverview.post.dialog.iva' | translate}}</span>-->
<!--            </div>-->
<!--            <div class="post-info-price__colum">-->
<!--              <span class="post-info-price__text">€ {{IVA}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="post-info-price-all">-->
<!--          <span class="post-info-price-all__text">TOTAL</span>-->
<!--          <span class="post-info-price-all__text">€ {{post.postPriceFinal}}</span>-->
<!--        </div>-->
<!--        </div>-->
<!--        <div *ngIf="statusChangingProcess; then spinner else postStatuses"></div>-->

<!--        <ng-template #spinner>-->
<!--          <div class='progress-spinner'>-->
<!--            <mat-spinner diameter='40'></mat-spinner>-->
<!--          </div>-->
<!--        </ng-template>-->

<!--        <ng-template #postStatuses>-->
<!--          <div class="post-info__btn-group" *ngIf='post.status === "pending"'>-->
<!--            <button [ngClass]="{'disabledPostButtons': disableButtons}" class="btn-circle primary-btn-circle" (click)='approvePost()' type='button'>-->
<!--              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--                <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>-->
<!--              </svg>-->
<!--              <span class="btn-circle__text">{{'campaignOverview.post.approve' | translate}}</span>-->
<!--            </button>-->

<!--            <button [ngClass]="{'disabledPostButtons': disableButtons}" class="btn-circle secondary-btn-circle" type="button" (click)='changePost()'>-->
<!--              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--                <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>-->
<!--              </svg>-->
<!--              <span class="btn-circle__text">{{'campaignOverview.post.change' | translate}}</span>-->
<!--            </button>-->

<!--            <button [ngClass]="{'disabledPostButtons': disableButtons}" class="btn-circle tertiary-btn-circle" type="button" (click)='declinePost()'>-->
<!--              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--                <path d="M37.77 18L30 25.77L22.23 18L18 22.23L25.77 30L18 37.77L22.23 42L30 34.23L37.77 42L42 37.77L34.23 30L42 22.23L37.77 18ZM30 0C13.41 0 0 13.41 0 30C0 46.59 13.41 60 30 60C46.59 60 60 46.59 60 30C60 13.41 46.59 0 30 0ZM30 54C16.77 54 6 43.23 6 30C6 16.77 16.77 6 30 6C43.23 6 54 16.77 54 30C54 43.23 43.23 54 30 54Z"></path>-->
<!--              </svg>-->

<!--              <span class="btn-circle__text">{{'campaignOverview.post.decline' | translate}}</span>-->
<!--            </button>-->
<!--          </div>-->

<!--          <div class="post-info__btn-group" *ngIf='post.status !== "pending"'>-->
<!--            <div class="btn-circle primary-btn-circle" *ngIf='post.status === "approved" || post.status === "published" || post.status === "pending_published"'>-->
<!--              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--                <path d="M43.77 16.74L24 36.51L13.23 25.77L9 30L24 45L48 21L43.77 16.74ZM30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>-->
<!--              </svg>-->
<!--              <span class="btn-circle__text">{{'campaignOverview.post.approved' | translate}}</span>-->
<!--            </div>-->

<!--            <div class="btn-circle secondary-btn-circle" *ngIf='post.status === "declined"'>-->
<!--              <svg class="btn-circle__svg" width="60" height="60" viewBox="0 0 60 60" fill="currentColor">-->
<!--                <path d="M27 39H33V45H27V39ZM27 15H33V33H27V15ZM29.97 0C13.41 0 0 13.44 0 30C0 46.56 13.41 60 29.97 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 29.97 0ZM30 54C16.74 54 6 43.26 6 30C6 16.74 16.74 6 30 6C43.26 6 54 16.74 54 30C54 43.26 43.26 54 30 54Z"></path>-->
<!--              </svg>-->
<!--              <span class="btn-circle__text">{{'campaignOverview.post.declined' | translate}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->


<!--        <div *ngIf="requestInsightProcess; then spinnerInsights else insightsButton"></div>-->
<!--        <ng-template #spinnerInsights>-->
<!--          <div class='progress-spinner insights-link'>-->
<!--            <mat-spinner diameter='30'></mat-spinner>-->
<!--          </div>-->
<!--        </ng-template>-->

<!--        <ng-template #insightsButton>-->
<!--          <span *ngIf='post.postSocialType !== "linkedin"' (click)='openPostInsight($event, post)' class='post-block__info insights-link' [ngClass]='post.status === "published" ? "three-items" : ""'>-->
<!--            <img src='assets/img/icons/insights.svg' alt='insights icon' />-->
<!--            <a href=''>{{'campaignOverview.post.dialog.insights' | translate}}</a>-->
<!--          </span>-->
<!--        </ng-template>-->
<!--        <span (click)='downloadMedia(post.id, $event)' class='post-block__info download-link' *ngIf='post.status === "published"'>-->
<!--            <a href=''>{{'campaignOverview.post.dialog.downloadMediaLink' | translate}}</a>-->
<!--        </span>-->

<!--        <span (click)='openPostInNewTab($event)' class='post-block__info new-tab-link'>-->
<!--            <a href=''>{{'campaignOverview.post.dialog.openPost' | translate}}</a>-->
<!--        </span>-->

<!--      </div>-->

<!--      <div class="post-info__colum">-->
<!--        <video *ngIf='post.isVideo' controls poster="{{post.postImageInfo.imageUrl}}" autoplay>-->
<!--        <source src="{{post.videoUrl}}" type="video/mp4">-->
<!--        </video>-->

<!--        <img *ngIf='!post.isVideo' [src]="post.postImageInfo.imageUrl" style='cursor: pointer' (click)='openImage(post.postImageInfo.imageUrl)' alt="" class="post-info__img">-->
<!--        <p class="feedback-block__description"><b>{{post.postText}}</b></p>-->

<!--        <p *ngIf='post.postSocialType === "instagram" || post.postSocialType === "reels" || post.postSocialType === "instagramstory"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.instagram}}</p>-->
<!--        <p *ngIf='post.postSocialType === "tiktok"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.tiktok}}</p>-->
<!--        <p *ngIf='post.postSocialType === "facebook"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.facebook}}</p>-->
<!--        <p *ngIf='post.postSocialType === "linkedin"' class="feedback-block__description feedback-block__tags">{{(campaignTags$ | async)?.linkedin}}</p>-->
<!--        <span class="post-info__submitted">{{'campaignOverview.post.submited' | translate}}: <b>{{post.createdDatetime | date: 'medium'}}</b></span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div (click)='ref.close()' class="ngneat-close-dialog"><svg viewBox="0 0 329.26933 329" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path></svg></div>-->

<!--</div>-->
<!--</ng-template>-->
<!--<ng-template #postEditingDialog>-->
<!--  <app-post-editing-dialog [feedbacksHistory]='feedbacksHistory' [editingHistory]="editingHistory" (isEditingChange)='isEditing = false'></app-post-editing-dialog>-->
<!--</ng-template>-->

<div class="modal modal-post">
  <span class="modal-post__subtitle">{{'campaignOverview.post.submited' | translate}}: <b>{{post.createdDatetime | date: 'medium'}}</b></span>
  <div class="modal-post__block">
    <div class="modal-post__box">
      <div class="modal-post__group-content">
        <img *ngIf='!post.isVideo && !post.carouselPostMedia' [src]="post.postImageInfo.imageUrl"
             style='cursor: pointer' (click)='openImage(post.postImageInfo.imageUrl)' alt="image"
             class="modal-post__img">

        <video *ngIf='post.isVideo && !post.carouselPostMedia' class="modal-post__img" controls
               poster="{{post.postImageInfo.imageUrl}}" autoplay>
          <source src="{{post.videoUrl}}" type="video/mp4">
        </video>

        <p-carousel *ngIf="post.carouselPostMedia" [value]="post.carouselPostMedia"
                    [numVisible]="1" [numScroll]="1" [circular]="false">
          <ng-template let-item pTemplate="item">
            <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
              <div *ngIf="!item.isVideo" class="mb-3" style="cursor: pointer">
                <img src="{{ item.url }}" class="w-6 shadow-2" alt="carousel post" (click)='openImage(item.url)' />
              </div>
              <div *ngIf="item.isVideo" class="mb-3" style="cursor: pointer">
                <video controls style="width: 100%">
                  <source src="{{item.url}}" type="video/mp4">
                </video>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
      <div class="modal-post__group">
        <div class="post-card-middle__author">
          <img [src]='profileImage' alt="influencer profile" (error)="setDefaultProfileImage()" class="post-card-middle__avatar">
          <div class="post-card-middle__group">
            <a *ngIf='post.postSocialType !== "tiktok"' [href]='post.socialAccountInfo.profileUrl' target='_blank' class="post-card-middle__name">{{post.socialAccountInfo.name || post.socialAccountInfo.username}}</a>
            <p *ngIf='post.postSocialType === "tiktok"' class="post-card-middle__name">{{post.socialAccountInfo.name}}</p>
            <span class="post-card-middle__date">{{post.socialAccountInfo.followers}} {{'campaignOverview.followers' | translate}}</span>
          </div>
          <img *ngIf='post.postSocialType === "instagram" || post.postSocialType === "reels" || post.postSocialType === "instagramstory"' src="assets/img/sc_icons/instagram--black.svg" alt="icon" class="post-card-top__icon">
          <img *ngIf='post.postSocialType === "facebook"' src="assets/img/sc_icons/facebook--black.svg" alt="icon" class="post-card-top__icon">
          <img *ngIf='post.postSocialType === "tiktok"' src="assets/img/sc_icons/tiktok--black.svg" alt="icon" class="post-card-top__icon">
          <img *ngIf='post.postSocialType === "linkedin"' src="assets/img/sc_icons/linkedin--black.svg" alt="icon" class="post-card-top__icon">
        </div>
        <div class="prices" *ngIf="showPrices">
          <div class="modal-brand__price">
            <span>{{'campaignOverview.post.dialog.media' | translate}}</span>
            <span>€{{post.postPriceFinal}}</span>
          </div>
          <div class="modal-brand__price">
            <span>{{'campaignOverview.post.dialog.iva' | translate}}</span>
            <span>€{{IVA}}</span>
          </div>
          <div class="post-card-price">
            <span class="post-card-price__text">{{'campaignOverview.post.dialog.total' | translate}}</span>
            <span class="post-card-price__number">€{{post.postPriceFinal}}</span>
          </div>
          <span class="modal-brand__text">{{'campaignOverview.post.dialog.techFee' | translate}}</span>
        </div>
        <div class="post-card__btn-group large" *ngIf='post.status === "pending"'>
          <button type="button" class="post-card-btn success" [ngClass]="{'disabledPostButtons': disableButtons}" (click)='approvePost()'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
            </svg>
            <span>{{'campaignOverview.post.accept' | translate}}</span>
          </button>
          <button type="button" class="post-card-btn warning" [ngClass]="{'disabledPostButtons': disableButtons}" (click)='changePost()'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="#D5CF4B" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 26H22V30H18V26ZM18 10H22V22H18V10ZM19.98 0C8.94 0 0 8.96 0 20C0 31.04 8.94 40 19.98 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 19.98 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
            </svg>
            <span>{{'campaignOverview.post.change' | translate}}</span>
          </button>
          <button type="button" class="post-card-btn danger" [ngClass]="{'disabledPostButtons': disableButtons}" (click)='declinePost()'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
            </svg>
            <span>{{'campaignOverview.post.decline' | translate}}</span>
          </button>
        </div>
        <div *ngIf='post.status !== "pending"' class="post-card__btn-group large">


          <div class="post-card-btn success" *ngIf='post.status === "approved" || post.status === "published" || post.status === "pending_published"'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="#4BD559" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.18 11.16L16 24.34L8.82 17.18L6 20L16 30L32 14L29.18 11.16ZM20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 36C11.16 36 4 28.84 4 20C4 11.16 11.16 4 20 4C28.84 4 36 11.16 36 20C36 28.84 28.84 36 20 36Z"/>
            </svg>
            <span>{{'campaignOverview.post.accepted' | translate}}</span>
          </div>

          <div class="post-card-btn danger" *ngIf='post.status === "declined"'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="#D54B4B" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.18 12L20 17.18L14.82 12L12 14.82L17.18 20L12 25.18L14.82 28L20 22.82L25.18 28L28 25.18L22.82 20L28 14.82L25.18 12ZM20 0C8.94 0 0 8.94 0 20C0 31.06 8.94 40 20 40C31.06 40 40 31.06 40 20C40 8.94 31.06 0 20 0ZM20 36C11.18 36 4 28.82 4 20C4 11.18 11.18 4 20 4C28.82 4 36 11.18 36 20C36 28.82 28.82 36 20 36Z"/>
            </svg>
            <span>{{'campaignOverview.post.declined' | translate}}</span>
          </div>
        </div>

        <div class="post-block__actions-wrapper">
          <div *ngIf="requestInsightProcess; then spinnerInsights else insightsButton"></div>
          <ng-template #spinnerInsights>
            <div class='progress-spinner insights-link'>
              <mat-spinner diameter='30'></mat-spinner>
            </div>
          </ng-template>

          <ng-template #insightsButton>
            <span *ngIf='post.postSocialType !== "linkedin" && post.postSocialType !== "tiktok"' (click)='openPostInsight($event, post)'
                  class='post-block__info insights-link' [ngClass]='post.status === "published" ? "three-items" : ""'>
              <img src='assets/img/icons/insights.svg' alt='insights icon' />
              <a href=''>{{ 'campaignOverview.post.dialog.insights' | translate }}</a>
            </span>
          </ng-template>
          <span
            (click)='downloadMedia(post.id, $event)'
            class='post-block__info download-link'
            *ngIf='post.status === "published"'>
              <a href=''>{{ 'campaignOverview.post.dialog.downloadMediaLink' | translate }}</a>
          </span>

          <span *ngIf="!post.platformName" (click)='openPostInNewTab($event)' class='post-block__info new-tab-link'>
            <a href=''>{{ 'campaignOverview.post.dialog.openPost' | translate }}</a>
          </span>
        </div>
      </div>
    </div>
    <div class="post-card-desc">
      <span class="post-card-desc__text">{{post.postText}}</span>
      <span *ngIf='post.postSocialType === "instagram" || post.postSocialType === "reels" || post.postSocialType === "instagramstory"' class="post-card-desc__tags">{{(campaignTags$ | async)?.instagram}}</span>
      <span *ngIf='post.postSocialType === "tiktok"' class="post-card-desc__tags">{{(campaignTags$ | async)?.tiktok}}</span>
      <span *ngIf='post.postSocialType === "facebook"' class="post-card-desc__tags">{{(campaignTags$ | async)?.facebook}}</span>
      <span *ngIf='post.postSocialType === "linkedin"' class="post-card-desc__tags">{{(campaignTags$ | async)?.linkedin}}</span>

    </div>
  </div>
  <button (click)='ref.close()' type="button" class="modal__close">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 33C26.25 33 33 26.25 33 18C33 9.75 26.25 3 18 3C9.75 3 3 9.75 3 18C3 26.25 9.75 33 18 33Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.7549 22.2449L22.2449 13.7549" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.2449 22.2449L13.7549 13.7549" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</div>
